<template>
  <div class="filters" ref="filtersWrapper">
    <a-popover placement="bottom" overlayClassName="filters-popover" trigger="click" v-model:open="visible"
      @openChange="handleOpen" :getPopupContainer="() => $refs.filtersWrapper">
      <artmisads-select-button showFilterIcon="true" :filterNum="filtersNum" showDownIcon="true">Filters
      </artmisads-select-button>
      <template #content>
        <div class="filters-down" ref="filtersDown">
          <div class="filters-name">Status</div>
          <artmisads-select class="width100" v-model:value="filters.status" @change="handleChange($event, 'status')"
            :getPopupContainer="() => $refs.filtersDown">
            <a-select-option value="all">All</a-select-option>
            <a-select-option value="active">Active</a-select-option>
            <a-select-option value="inactive">Inactive</a-select-option>
          </artmisads-select>
          <div class="filters-name">Commission Min</div>
          <div class="commission-number width100">
            <artmisads-input-number v-model:value="filters.commission" placeholder="Please enter..." :step="0.1" min="0"
              max="100" suffix="%" @change="handleChange($event, 'comission')">
            </artmisads-input-number>
          </div>
          <div class="filters-name">Availability </div>
          <div class="width100">
            <artmisads-select class="width100" v-model:value="filters.availability"
              @change="handleChange($event, 'availability')" :getPopupContainer="() => $refs.filtersDown">
              <a-select-option value="all">All</a-select-option>
              <a-select-option value="IN_STOCK">In Stock</a-select-option>
              <a-select-option value="OUT_OF_STOCK">Out of Stock</a-select-option>
            </artmisads-select>
          </div>
          <div class="filters-name">Price </div>
          <div class="width100">
            <artmisads-select class="width100" v-model:value="filters.price" @change="handleChange($event, 'price')"
              :getPopupContainer="() => $refs.filtersDown" placement="topRight">
              <a-select-option value="all">All</a-select-option>
              <a-select-option value="get10">Under {{ currencySymbol }}10</a-select-option>
              <a-select-option value="get15">{{ currencySymbol }}10 to {{ currencySymbol }}25</a-select-option>
              <a-select-option value="get25">{{ currencySymbol }}25 to {{ currencySymbol }}50</a-select-option>
              <a-select-option value="get50">{{ currencySymbol }}50 to {{ currencySymbol }}100</a-select-option>
              <a-select-option value="get100">{{ currencySymbol }}100 to {{ currencySymbol }}200</a-select-option>
              <a-select-option value="get200">{{ currencySymbol }}200 & Above </a-select-option>

            </artmisads-select>
          </div>
          <div class="filters-name">Deal</div>
          <artemisads-segmented v-model:value="filters.deal" @change="handleChange($event, 'deal')"
            :options="['Off', 'All', 'Active', 'Upcoming']" />
        </div>
        <div class="btns">
          <a @click="reset" :class="'reset' + (filtersNum ? '' : ' disabled')" href="javascript:;">
            <span style="margin-right: 3px">Reset</span><span class="filter-num" v-show="filtersNum">{{ filtersNum
            }}</span><span style="margin-left: 3px">Filters</span>
          </a>
          <artmisads-button type="primary" size="middle" @click="onSave">Confirm</artmisads-button>
        </div>
      </template>
    </a-popover>

  </div>
</template>
<script setup name="FilterOptions">
import { ref, reactive, computed } from 'vue';
import { CURRENCY_MAPPER } from '@/contants/common';
import { useUserInfoStore } from '~/storeData/index';

const store = useUserInfoStore();
const currencySymbol = computed(() => {
  return CURRENCY_MAPPER[store.userInfo.countryCode].currency;
});
const emit = defineEmits(['get-data']);
let visible = ref(false);
let filtersNum = ref(0);
let filtersDown = ref();
let filtersWrapper = ref();
let filters = reactive({
  deal: 'Off',
  price: 'all',
  status: 'all',
  commission: null,
  availability: 'all',
})
let filtersOrigi = {
  deal: 'Off',
  price: 'all',
  status: 'all',
  commission: null,
  availability: 'all',
}
let options = [];
const prices = [
  {
    lable: 'get10',
    maxPrice: 10,
    minPrice: 0
  },
  {
    lable: 'get15',
    maxPrice: 25,
    minPrice: 10
  },
  {
    lable: 'get25',
    maxPrice: 50,
    minPrice: 25
  },
  {
    lable: 'get50',
    maxPrice: 100,
    minPrice: 50
  },
  {
    lable: 'get100',
    maxPrice: 200,
    minPrice: 100
  },
  {
    lable: 'get200',
    maxPrice: null,
    minPrice: 200
  },

]
const onSave = () => {
  visible.value = false
  let req = { ...filters };
  if (req.price != 'all') {
    prices.forEach(v => {
      if (v.lable == req.price) {
        req.maxPrice = v.maxPrice;
        req.minPrice = v.minPrice;
      }
    })
    req.page = 1;
  }
  delete req.price;
  emit('get-data', req)
}
const handleChange = (e, itemName) => {
  let isMark = options.includes(itemName)
  if (!isMark && e != filtersOrigi[itemName]) {
    options.push(itemName);
    filtersNum.value++
  }
  if (isMark && e == filtersOrigi[itemName]) {
    let index = options.findIndex(item => item == itemName);
    options.splice(index, 1);
    if (filtersNum) { filtersNum.value-- }
  }
  if (itemName == 'comission') {
    filters.commission = e;

    if (isNaN(parseFloat(e))) {
      filters.commission = undefined;
      return;
    }

    filters.commission = Math.floor(e * 10) / 10;
  }
}
const reset = () => {
  visible.value = false
  let _filters = {
    deal: 'Off',
    price: 'all',
    status: 'all',
    commission: null,
    availability: 'all',
  }
  options = [];
  Object.assign(filters, _filters);
  filtersNum.value = 0;

  emit('get-data', {})
}
</script>
<style lang="less" scoped>
@import '../../../../assets/less/filter-popup.less';

.filters:deep {

  .ant-popover-arrow {
    left: auto;
    right: 73px;
  }

  .artemisads-segmented {
    width: 100%;

    .ant-segmented-group {
      width: 100%;
      justify-content: space-between;
    }
  }

  .ant-input-number-handler-wrap {
    opacity: 1 !important;
  }
}
</style>
<template>
  <artmisads-modal :open="params.open" title="Are you sure you want to Deactivate this Product?" :width="500"
    :onCancel="onCancel" :destroyOnClose="true" :maskClosable="false">
    <a-spin :spinning="loading">
      <div class="inactive-content">
        <p v-if="params.hasVariations">
          Deactivating a Product will archive the active Publisher/Creator links
          of the product and its related variations, and also make them
          unavailable to Publishers and Creators.
        </p>
        <p v-else>
          Deactivating a Product will archive all active Publisher/Creator
          links. and make this product unavailable to publishers and creators.
        </p>
      </div>
    </a-spin>
    <template #footer>
      <artmisads-button @click="emit('toggleModalVisibility', false)">Cancel</artmisads-button>
      <artmisads-button @click="confirmInactive" type="primary">Confirm</artmisads-button>
    </template>
  </artmisads-modal>
</template>

<script setup>
import { ref } from 'vue';
import { message } from 'ant-design-vue';
import { productsApi } from '@/server/index';

const loading = ref(false);
const { params } = defineProps(['params']);
const emit = defineEmits(['toggleModalVisibility', 'updateProductStatus', 'openNotification']);

const onCancel = () => {
  emit('toggleModalVisibility', false);
}

const confirmInactive = () => {
  loading.value = true;

  productsApi.inactiveProduct([params.product.id]).then(res => {
    const { success, code, error } = res;

    if (success) {
      message.success('Success');
      emit('updateProductStatus', 'inactive');
    } else {
      if (code === '1014') {
        emit('openNotification', error);
      } else {
        message.error('Failed');
      }
    }

    loading.value = false;
  })
}
</script>

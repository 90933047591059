<template>
  <artmisads-modal :open="params.open" :width="500" title="Are you sure you want to Activate this Product?"
    okText="Confirm" @ok="emit('activateProduct', params.index, params.product.id)"
    @cancel="emit('toggleModalVisibility', false)">
    <a-spin :spinning="params.loading">
      <div class="active-product">
        <div class="title-ext">
          Activate the Product, and both the product itself and its related
          variations will be activated.
        </div>
      </div>
    </a-spin>
  </artmisads-modal>
</template>

<script setup>
const { params } = defineProps(['params']);
const emit = defineEmits(['toggleModalVisibility', 'activateProduct']);
</script>
